var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-material-card',{staticClass:"px-4 py-2",attrs:{"color":_vm.appTitleColor,"icon":_vm.appIcon},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.appTitle)+" "),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.showDetailsButton)?_c('v-btn',_vm._g({attrs:{"absolute":"","dark":"","fab":"","top":"","right":"","small":"","color":_vm.appTitleColor},on:{"click":_vm.onTakebackDetails}},on),[_c('v-icon',[_vm._v("mdi-magnify")])],1):_vm._e()]}}])})],1)]},proxy:true}])},[_c('v-row',_vm._l((_vm.dashboardSummaryItems),function(item){return _c('v-col',{key:item.recordType,attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-card',{attrs:{"color":_vm.summaryItemColor(item.recordType)}},[_c('v-card-title',{staticClass:"text-h4 white--text"},[_vm._v(" "+_vm._s(item.recordTypeText)+" ")]),_c('v-card-text',{staticClass:"text-h5 white--text"},[_vm._v(" "+_vm._s(item.quantity.toFixed(2))+" "+_vm._s(item.unitText)+" ")])],1)],1)}),1),_c('v-row',[(_vm.type === 1 || _vm.type === 2)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.DetailHeaders,"items":_vm.dashboardDetails,"item-key":"lineNumber","hide-default-footer":"","single-select":""},scopedSlots:_vm._u([{key:"item.partnerName",fn:function(ref){
var item = ref.item;
return [(item.partnerName.length != 0)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.partnerName)+" ")]):_vm._e()]}},{key:"item.intransitWasteQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.intransitWasteQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.intransitProductQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.intransitProductQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.availableQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.availableQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.totalQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(Number(item.totalQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.designatedQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.designatedQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.notDesignatedQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.notDesignatedQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}}],null,false,274656144)})],1):_vm._e(),(_vm.type === 3 || _vm.type === 4)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.WasteDetailHeaders,"items":_vm.dashboardDetails,"item-key":"lineNumber","hide-default-footer":"","single-select":""},scopedSlots:_vm._u([{key:"item.partnerName",fn:function(ref){
var item = ref.item;
return [(item.partnerName.length != 0)?_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.partnerName)+" ")]):_vm._e()]}},{key:"item.intransitWasteQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.intransitWasteQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}},{key:"item.availableQuantity",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(Number(item.availableQuantity).toFixed(2))+" "+_vm._s(item.unitText)+" ")])]}}],null,false,3428790272)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }