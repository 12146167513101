<template>
  <base-material-card
    :color="appTitleColor"
    class="px-4 py-2"
    :icon="appIcon"
  >
    <template v-slot:after-heading>
      <div class="display-2 font-weight-bold">
        {{ appTitle }}
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              v-if="showDetailsButton"
              absolute
              dark
              fab
              top
              right
              small
              :color="appTitleColor"
              v-on="on"
              @click="onTakebackDetails"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </template>
        </v-menu>
      </div>
    </template>
    <v-row>
      <v-col
        v-for="item in dashboardSummaryItems"
        :key="item.recordType"
        cols="12"
        sm="6"
        md="4"
        lg="2"
      >
        <v-card :color="summaryItemColor(item.recordType)">
          <v-card-title class="text-h4 white--text">
            {{ item.recordTypeText }}
          </v-card-title>
          <v-card-text class="text-h5 white--text">
            {{ item.quantity.toFixed(2) }} {{ item.unitText }}
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="type === 1 || type === 2"
        cols="12"
      >
        <v-data-table
          :headers="DetailHeaders"
          :items="dashboardDetails"
          item-key="lineNumber"
          hide-default-footer
          single-select
          class="elevation-1"
        >
          <template v-slot:item.partnerName="{ item }">
            <div
              v-if="item.partnerName.length != 0"
              class="font-weight-bold"
            >
              {{ item.partnerName }}
            </div>
          </template>
          <template v-slot:item.intransitWasteQuantity="{ item }">
            <div>
              {{ Number(item.intransitWasteQuantity).toFixed(2) }}
              {{ item.unitText }}
            </div>
          </template>
          <template v-slot:item.intransitProductQuantity="{ item }">
            <div>
              {{ Number(item.intransitProductQuantity).toFixed(2) }}
              {{ item.unitText }}
            </div>
          </template>
          <template v-slot:item.availableQuantity="{ item }">
            <div>
              {{ Number(item.availableQuantity).toFixed(2) }}
              {{ item.unitText }}
            </div>
          </template>
          <template v-slot:item.totalQuantity="{ item }">
            <div class="font-weight-bold">
              {{ Number(item.totalQuantity).toFixed(2) }} {{ item.unitText }}
            </div>
          </template>
          <template v-slot:item.designatedQuantity="{ item }">
            <div>
              {{ Number(item.designatedQuantity).toFixed(2) }}
              {{ item.unitText }}
            </div>
          </template>
          <template v-slot:item.notDesignatedQuantity="{ item }">
            <div>
              {{ Number(item.notDesignatedQuantity).toFixed(2) }}
              {{ item.unitText }}
            </div>
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="type === 3 || type === 4"
        cols="12"
      >
        <v-data-table
          :headers="WasteDetailHeaders"
          :items="dashboardDetails"
          item-key="lineNumber"
          hide-default-footer
          single-select
          class="elevation-1"
        >
          <template v-slot:item.partnerName="{ item }">
            <div
              v-if="item.partnerName.length != 0"
              class="font-weight-bold"
            >
              {{ item.partnerName }}
            </div>
          </template>
          <template v-slot:item.intransitWasteQuantity="{ item }">
            <div>
              {{ Number(item.intransitWasteQuantity).toFixed(2) }}
              {{ item.unitText }}
            </div>
          </template>
          <template v-slot:item.availableQuantity="{ item }">
            <div>
              {{ Number(item.availableQuantity).toFixed(2) }}
              {{ item.unitText }}
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </base-material-card>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  export default {
    name: 'CommitmentSummaryComponent',
    props: {
      type: {
        type: Number,
        default: 1,
      },
    },
    data: function () {
      return {
        DetailHeaders: [
          {
            text: this.$t('col-dashboard-Partner'),
            align: 'start',
            value: 'partnerName',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
          },
          {
            text: this.$t('col-dashboard-Receiving'),
            align: 'end',
            value: 'intransitWasteQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-Shipping'),
            align: 'end',
            value: 'intransitProductQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-Available'),
            align: 'end',
            value: 'availableQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-Total'),
            align: 'end',
            value: 'totalQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-Designated'),
            align: 'end',
            value: 'designatedQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-NotDesignated'),
            align: 'end',
            value: 'notDesignatedQuantity',
            sortable: false,
            class: 'deep-purple lighten-4 display-1 font-weight-bold',
            width: '12%',
          },
        ],
        WasteDetailHeaders: [
          {
            text: this.$t('col-dashboard-Partner'),
            align: 'start',
            value: 'partnerName',
            sortable: false,
            class: 'brown lighten-2 display-1 font-weight-bold',
          },
          {
            text: this.$t('col-dashboard-IntransitWaste'),
            align: 'end',
            value: 'intransitWasteQuantity',
            sortable: false,
            class: 'brown lighten-2 display-1 font-weight-bold',
            width: '12%',
          },
          {
            text: this.$t('col-dashboard-Available'),
            align: 'end',
            value: 'availableQuantity',
            sortable: false,
            class: 'brown lighten-2 display-1 font-weight-bold',
            width: '12%',
          },
        ],
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
        purchaseSummary: 'dashboardData.purchaseCommitment',
        purchaseDetails: 'dashboardData.purchaseCommitmentDetails',
        salesSummary: 'dashboardData.salesCommitment',
        salesDetails: 'dashboardData.salesCommitmentDetails',
        wasteSummary: 'dashboardData.wasteCommitment',
        wasteDetails: 'dashboardData.wasteCommitmentDetails',
      }),
      appTitle: function () {
        let title = ''
        switch (this.type) {
          case 1:
            title = this.$t('dashboardtitleSummary-Purchase')
            break
          case 2:
            title = this.$t('dashboardtitleSummary-Sales')
            break
          case 3:
          case 4:
            title = this.$t('dashboardtitleSummary-Waste')
            break
        }
        return title
      },
      appTitleColor: function () {
        let titleColor = 'deep-purple darken-2'
        switch (this.type) {
          case 1:
            titleColor = 'deep-purple darken-2'
            break
          case 2:
            titleColor = 'green darken-4'
            break
          case 3:
          case 4:
            titleColor = 'brown darken-4'
            break
        }
        return titleColor
      },
      appIcon: function () {
        let icon = 'mdi-truck-check'
        switch (this.type) {
          case 2:
            icon = 'mdi-clipboard-arrow-up'
            break
          case 3:
          case 4:
            icon = 'mdi-recycle'
            break
        }
        return icon
      },
      dashboardSummaryItems: function () {
        let details = []
        if (this.purchaseSummary !== undefined) {
          details = this.purchaseSummary
          switch (this.type) {
            case 2:
              details = this.salesSummary
              break
            case 3:
            case 4:
              details = this.wasteSummary
              break
          }
        }
        return details
      },
      dashboardDetails: function () {
        let details = []
        switch (this.type) {
          case 1:
            details = this.purchaseDetails
            break
          case 2:
            details = this.salesDetails
            break
          case 3:
          case 4:
            details = this.wasteDetails
            break
        }
        return details
      },
      showDetailsButton: function () {
        let showDetail = false
        switch (this.type) {
          case 1:
          case 2:
            showDetail = true
            break
          default:
            break
        }
        return showDetail
      },
    },
    mounted: function () {},
    methods: {
      onTakebackDetails: function () {
        this.$router.push({
          path: `/dashboard/details/${this.type}`,
        })
      },
      summaryItemColor: function (recordType) {
        let color = 'deep-purple lighten-1'
        switch (this.type) {
          case 1:
            switch (recordType) {
              case 1:
                color = 'deep-purple lighten-1'
                break
              case 2:
                color = 'deep-purple darken-4'
                break
              case 3:
                color = 'teal darken-3'
                break
              case 4:
                color = 'blue darken-4'
                break
              case 11:
                color = 'blue-grey darken-3'
                break
              case 12:
                color = 'teal darken-1'
                break
            }
            break
          case 2:
            switch (recordType) {
              case 1:
                color = 'deep-orange darken-3'
                break
              case 2:
                color = 'green darken-4'
                break
              case 3:
                color = 'lime darken-4'
                break
              case 4:
                color = 'cyan darken-4'
                break
              case 11:
                color = 'brown darken-3'
                break
              case 12:
                color = 'lime darken-3'
                break
            }
            break
          case 3:
          case 4:
            switch (recordType) {
              case 21:
                color = 'blue-grey darken-3'
                break
              case 4:
                color = 'brown darken-3'
                break
              case 11:
                color = 'orange darken-4'
                break
              case 23:
                color = 'lime darken-3'
                break
            }
            break
        }
        return color
      },
    },
  }
</script>
